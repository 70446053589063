
export default {
  data: function () {
    return {
      autoCompleteData: []
    }
  },
  mounted () {
    this.initChatBot()
  },
  methods: {
    async loadAutoCompleteData (jsonUrl) {
      this.autoCompleteData = await (await fetch(jsonUrl)).json()
    },

    searchBotResponses (inputText) {
      // 입력 텍스트에 대해 매칭 여부를 판단하는 함수
      const matchesText = text => inputText.includes(text)

      // 결과 리스트를 초기화
      const result = []

      // 각 항목에 대해 반복
      for (const item of this.autoCompleteData) {
        // 0. utteranceList와 매칭되는지 확인 (완전일치시 1개만 표시)
        if (item.utteranceList.some(matchesText)) {
          result.length = 0
          result.push(...item.utteranceList)
          break
        }
        // 1. title과 매칭되는지 확인
        if (matchesText(item.title)) {
          result.push(...item.utteranceList)
          continue // 이미 매칭되었으면 해당 항목 처리 후 다음 항목으로 넘어감
        }

        // 2. keywordList와 매칭되는지 확인
        if (item.keywordList.some(matchesText)) {
          result.push(...item.utteranceList)
          continue
        }
      }
      return result
    },
    getDeviceType: () => {
      const userAgent = navigator.userAgent
      if (/iPhone|iPad|iPod/.test(userAgent)) { return 'ios' }
      if (/Android/.test(userAgent)) { return 'android' }
      return 'pc'
    },
    initChatBot () {
      // CenterFlow가 로드되었는지 확인한 후 초기화
      if (typeof window.Centerflow !== 'undefined' && typeof window.Centerflow?.configStore?.chatOptions === 'undefined') {
        this.loadAutoCompleteData(
          'https://bd30bb8812b444408d814bbcc56388ed.kakaoiedge.com/auto_complete.json') // 추천발화 리스트
        window.Centerflow.init({
          serviceName: 'daisomall-1732110520054',
          serviceKey: '1AIIRyD7Gh01WQeLozhjNLC21aRhXQ2oFsnOeRtKCYfkNhp0Ib0rZFlp5WP42zxs390R8qny6sTitkpP6ENQ3muFjUedtArXj5pA31ijhsys3zyYtFX5t38',
          botDataPhase: process.env.CHATBOT_BUILD_PHASE,
        })
        window.Centerflow.UI.createChat({
          chatHeight: this.getDeviceType() === 'pc' ? 100 : '',
          useAsyncResponse: true,
          title: '다이소몰 챗봇 상담',
          subTitle: '봇과 대화중',
          profileName: '다이소몰 챗봇',
          profileImageUrl: 'https://bd30bb8812b444408d814bbcc56388ed.kakaoiedge.com/daiso_profile.png',
          bubbleBackgroundColor: '#28323C',
          bubbleTextColor: '#ffffff',
          profileImageBorderLess: false,
          quickReplyColor: '#DED6D2',
          quickReplyButtonBorderColor: '#F3F3F3',
          quickReplyButtonTextColor: '#161616',
          customMenuTextColor: '#FFFFFF',
          customMenuBackgroundColor: '#28323C',
          useCustomFloatingButton: true,
          inputPlaceholder: '문의내용을 입력해 주세요.',
          activeKeyword: '시작',
          customMenus: [
            {
              id: 0,
              title: '처음으로',
              icon: 'home',
              type: 'block',
              message: '처음으로',
              blockId: 'kepBwek-4pWSAqHpuLzQbKh-A'
            },
            {
              id: 1,
              title: '이전으로',
              icon: 'prev',
              type: 'block',
              message: '이전으로',
              blockId: 'kepR8zAPN2BTFSdJYM7ldn5dg'
            },
            {
              id: 2,
              title: '문의하기',
              icon: 'info',
              type: 'link'
            }
          ],
          onChatSend: (text) => {
            window.Centerflow.UI.showAutoComplete([])
            this.user_utter = text
          },
          onKeyDownUserChat: (text) => {
            const searchText = text.trim()
            const searchResult = searchText.length >= 2 ? this.searchBotResponses(searchText) : []

            if (searchResult.length > 0) {
              window.Kakaoi.UI.showAutoComplete(searchResult)
            } else {
              window.Kakaoi.UI.hideAutoComplete()
              window.Kakaoi.UI.showAutoComplete([])
            }
          },
          onCustomMenuClick: (id) => {
            switch (id) {
              case 2:
                if (this.getDeviceType() === 'pc') {
                  // PC 환경
                  // 링크 이동 로직
                  window.$nuxt?.$router?.push('/cs/csl/SCR_CSL_0001') // 링크 이동 로직
                  window.Centerflow?.UI?.clickCloseButton() // 플로팅버튼 닫기 실행
                } else {
                  // 모바일 환경
                  window.$nuxt?.$router?.push('/cs/csd/SCR_CSD_0001') // 링크 이동 로직
                  window.Centerflow?.UI?.clickCloseButton() // 플로팅버튼 닫기 실행
                }
                break
            }
          },
          blockButtonClickInterceptor: (buttonInfo) => {
            if (buttonInfo.type === 'link') {
              const linkUrl = this.getDeviceType() === 'pc' // 기기환경에 따른 url 선택
                ? buttonInfo.pcUrl
                : buttonInfo.moUrl || buttonInfo.pcUrl

              linkUrl && window.open(linkUrl, '_self') // link url 이 존재하는 경우만 실행

              return false
            }
            return true
          }
        })
        console.debug('CenterFlow 성공')
      } else {
        console.error('CenterFlow 실패.')
      }
    },
    openChatBot () {
      window.Centerflow?.UI?.clickFloatingButton()
    }
  }
}
