
import { commonfunc } from '~/mixin/common'
import ChatbotButton from '~/components/common/button/chatbot/ChatbotButton.vue'

export default {
  components: {
    ChatbotButton
  },
  mixins: [commonfunc],
  props: {
    quickOn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      todayProduct: [],
      isBtnActive: false,
      isQuickList: false,
      popover: true, // 23.10.19 사라짐 추가
      srcAfter: 'cHJvakNkPURBSSZjaGFubmVsSWQ9MzI4',
      isChatbotIconShow: false,
      intervalChatbotIconShow: '',
      timeOutChatbotIconHidden: '',
      mbEid2: this.$store.getters['session/getMb'].mbEid2, // 고객 아이디를 화면에 담을 것.
      chatbotUrl: process.env.CHATBOT_URL,
    }
  },
  watch: {
    quickOn: {
      handler () {
        if (this.quickOn && this.popover) {
          // 23.10.19 사라짐 추가
          this.popoverCtr()
        }
      }
    },
    $route: {
      deep: true,
      handler () {
        this.getRecentlyProducts(JSON.parse(localStorage.getItem('visitedPdNo')))
      }
    }
  },
  created () {
    console.group('created' + '>>' + (new Date()).toLocaleString() + '.' + (new Date()).getMilliseconds())
    this.mbEid2 = this.$store.getters['session/getMb'].mbEid2 // 고객 아이디를 화면에 담을 것.
    console.log('mbEid2:', this.$store.getters['session/getMb'].mbEid2)
    console.groupEnd()
  },
  mounted () {
    console.group('mounted' + '>>' + (new Date()).toLocaleString() + '.' + (new Date()).getMilliseconds())
    this.getRecentlyProducts(JSON.parse(localStorage.getItem('visitedPdNo')))
    // this.activateChatbotIcon() jsTag.setAttribute('onload', this.activateChatbotIcon()) 에서 호출함.

    // this.mbEid2 = this.$store.getters['session/getmbEid2'] // 고객 아이디를 화면에 담을 것.
    this.mbEid2 = this.$store.getters['session/getMb'].mbEid2
    console.log('mbEid2:', this.$store.getters['session/getMb'].mbEid2)
    console.groupEnd()
  },
  methods: {
    onErrorImg (e) {
      e.target.src = '/images/tempLy/today_view_thum.png'
    },
    goScrollTop (e) {
      this.$emit('scrollTop')
    },
    async getRecentlyProducts (cookiePdNo) {
      this.todayProduct = []
      const payload = []
      if (this.isNotEmpty(cookiePdNo)) {
        cookiePdNo.forEach((item) => {
          const visitedPdNo = {
            pdNo: item.pdNo
          }
          payload.push(visitedPdNo)
        })
      }

      if (payload.length > 0) {
        const resData = await this.$AxiosBuilder
          .data(payload)
          .build()
          .post('/pdo/pdThumbSelList')
          .then((res) => {
            return res.data.data
          })

        if (resData !== null && resData !== undefined && resData.length > 0) {
          payload.forEach((reqItem) => {
            resData.forEach((resItem, resIndex) => {
              if (resIndex < 4) {
                if (reqItem.pdNo === resItem.pdNo) {
                  const recentlyProd = {
                    id: resItem.pdNo,
                    thumb: resItem.pdImgUrl,
                    name: resItem.pdNm
                  }
                  this.todayProduct.push(recentlyProd)
                }
              }
            })
          })
          console.log('최근본')
          console.log(this.todayProduct)
        }
      }
    },
    resetRecentlyProducts () {
      this.todayProduct = []
      localStorage.setItem('visitedPdNo', null)
    },
    alarmClose (e) {
      e.target.closest('.alarm-tool').style.display = 'none'
    },
    toggleQuick () {
      this.isBtnActive = !this.isBtnActive
      this.isQuickList = !this.isQuickList
    },
    // 23.10.19 사라짐 추가
    popoverCtr () {
      setTimeout(() => {
        this.popover = false
      }, 3000)
    },
    goMembership () {
      const mbMngNo = this.$store.getters['session/getMbMngNo']
      if (!mbMngNo) {
        this.$router.push('/mb/mbb/SCR_MBB_0097A')
      } else {
        this.$router.push({ path: '/ms/msb/SCR_MSB_0001' })
      }
    }
  }
}
